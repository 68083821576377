.bot {
  display: flex;
  flex-direction: column;
  /* width: 570px; */
  max-width: 570px;
  height: 700px;
  min-height: 570px;
  margin: auto;
  border-radius: 8px;
  background-color: #323232;
  overflow: hidden;
}

.chatText {
  color: #003558;
}

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: calc(100% - 53px);
}

.formFooter {
  position: relative;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 15px;
  margin-top: auto;
  background-color: #323232;
}

.formFooter input {
  background-color: #323232;
}

.messagesBox {
  flex: 1;
  /* overflow-y: auto; */
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 10px;
  padding-bottom: 40px;
  background-color: #162128;
}

.message {
  width: fit-content;
  margin-bottom: 10px;
  padding: 10px 16px;
  word-wrap: break-word;
}

.message-bot {
  align-self: flex-start;
  background-color: #484848;
  color: #fff;
  border-radius: 2px 24px 24px 24px;
  margin-left: 40px;
}

.messageHeaderBot {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.messageAvatar {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.messageName {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}

.messageDot {
  width: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
}

.messageTime {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}

.answerButtonsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
}

.inputMessage {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 15px;
  color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #323232 inset !important;
  box-shadow: 0 0 0px 1000px #323232 inset !important;
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
}

.buttonAnswer {
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 10px;
  color: #fff;
  border-radius: 22px;
  border: 1px solid #fff;
  cursor: pointer;
  background-color: #162128;
}

.buttonAnswer.active {
  background-color: #2f2f2f;
  border: 1px solid #2f2f2f;
  color: #ffd204;
}

.buttonSend {
  width: 120px;
  padding: 10px;
  background-color: #ffd204;
  color: #003558;
  border-radius: 22px;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s ease;
}

@keyframes clickEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.buttonSend:active {
  animation: clickEffect 0.2s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.buttonUndo {
  margin-left: 5px;
  width: 70px;
  padding: 10px;
  background-color: #ffd204;
  color: #003558;
  border-radius: 22px;
  cursor: pointer;
  font-size: 15px;
}

.errorMessage {
  position: absolute;
  top: -30px;
  left: 25px;
  /* transform: translateX(-50%); */
  color: red;
  font-size: 15px;
}

.centerVertically {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.errorBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  border: 1px solid #ff0000;
  border-radius: 8px;
  color: #fff;
  text-align: center;
}

.errorBannerMessage {
  color: #ff0000;
}
