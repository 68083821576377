.header {
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #003558;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.headerText {
  margin: 0 8px;
  color: #fff;
}

.greenActiveDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3abf38;
}
