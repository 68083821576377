.message {
  width: fit-content;
  margin-bottom: 10px;
  padding: 10px 16px;
  word-wrap: break-word;
}

.messageUserWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.message-bot {
  align-self: flex-start;
  background-color: #484848;
  color: #fff;
  border-radius: 2px 24px 24px 24px;
  margin-left: 40px;
}

.message-user {
  align-self: flex-end;
  margin-left: auto;
  background-color: #2f2f2f;
  color: #ffd204;
  border-radius: 24px 2px 24px 24px;
  /* margin-right: 40px; */
}

.messageHeaderBot {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.messageHeaderUser {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto;
  margin-right: auto;

  align-items: center;
  margin-bottom: 8px;
}

.messageAvatar {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.messageAvatarInitials {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  background-color: #003558;
}

.messageName {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}

.messageDot {
  width: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
}

.messageTime {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}
