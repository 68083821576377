:root {
  --animation-duration: 1.2s; /* Define the variable for animation duration */
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  animation: dots-loader var(--animation-duration) ease-in-out infinite
    alternate; /* Use the variable for animation duration */
}

@keyframes dots-loader {
  0%,
  100% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
}

/* Apply animation delay based on the calculated duration */
div:nth-child(1) {
  animation-delay: calc(var(--animation-duration) / 3 * 0);
}

div:nth-child(2) {
  animation-delay: calc(var(--animation-duration) / 3 * 1);
}

div:nth-child(3) {
  animation-delay: calc(var(--animation-duration) / 3 * 2);
}
